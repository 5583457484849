<template>
  <v-form>
    <v-row dense>
      <v-col cols="2">
        <v-text-field
          outlined
          dense
          readonly
          filled
          label="Code"
          v-model="record.CardCode"
        ></v-text-field>
      </v-col>

      <v-col cols="4">
        <v-text-field
          dense
          outlined
          filled
          readonly
          label="Name"
          v-model="record.CardName"
        ></v-text-field>
      </v-col>

      <!-- doc num -->
      <!-- <v-col cols="2">
        <v-autocomplete
          outlined
          dense
          v-model="record.Series"
          :items="series"
          item-text="name"
          item-value="value"
          label="Series"
        ></v-autocomplete>
      </v-col> -->

      <v-col cols="4">
        <v-text-field
          outlined
          dense
          label="No."
          readonly
          filled
          v-model="record.DocNum"
        ></v-text-field>
      </v-col>
      <!-- end -->

      <v-col cols="3">
        <DatePicker
          @date="setPostingDate"
          :myDate="record.DocDate"
          :title="`Posting Date`"
        ></DatePicker>
      </v-col>

      <v-col cols="3">
        <DatePicker
          @date="setDeliveryDate"
          :myDate="record.DocDueDate"
          :title="`Due Date`"
        ></DatePicker>
      </v-col>

      <v-col cols="3">
        <DatePicker
          @date="setDocumentDate"
          :myDate="record.TaxDate"
          :title="`Document Date`"
        ></DatePicker>
      </v-col>

      <v-col cols="3">
        <v-text-field
          outlined
          dense
          label="Reference"
          v-model="record.Ref1"
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>

      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <!-- start of items table -->
          <v-data-table
            hide-default-footer
            dense
            v-model="selectedDocs"
            @item-selected="clickedDocs"
            item-key="id"
            :headers="itemHeaders"
            :items="saleDocs"
          >
            <!-- selected slot -->
            <!-- <template v-slot:item.Selected="{ item }">
              <v-simple-checkbox v-model="item.Selected"></v-simple-checkbox>
            </template>-->
            <!-- end of selected slot -->

            <!-- start of doc date template -->
            <template v-slot:item.DocDate="{ item }">{{ item.DocDate | moment("Do MMM YYYY")}}</template>
            <!-- end of doc date template -->

          </v-data-table>
          <!-- end of items table -->
        </v-col>
      </v-row>

      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>

      <v-col cols="7">
        <v-row>
          <v-col cols="6">
            <v-textarea
              outlined
              v-model="record.remarks"
              label="Remarks"
              document_lines="2"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              outlined
              dense
              readonly
              filled
              label="External Reference"
              v-model="record.ExtRef"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="5">

        <v-row>
          <v-col cols="6">
            <v-text-field
              dense
              outlined
              label="Total Amount Due"
              v-model="record.DocTotal"
              color="red"
              readonly
              filled
            ></v-text-field>
          </v-col>

          <!-- <v-col cols="6">
            <v-btn
              icon
              @click="paymentDialog = true"
            >
              <v-icon color="red">mdi-sack-percent</v-icon>
            </v-btn>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              single-line
              dense
              outlined
              label="Open Balance"
              v-model="OpenBalance"
              readonly
              filled
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <!-- selected items list -->
    </v-row>

    <v-row>
      <v-col cols="10">
        <v-btn
          color="accent"
          @click="sendData"
        >
          <v-icon left>mdi-content-save</v-icon>Save
        </v-btn>
      </v-col>
    </v-row>

    <!-- start of payment means modal -->
    <v-dialog
      v-model="paymentDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="accent"
        >
          <v-toolbar-title>
            <v-btn
              icon
              dark
              @click="paymentDialog = false"
            >
              <v-icon dark>mdi-keyboard-backspace</v-icon>
            </v-btn>Payment Means
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="paymentDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-container>
          <v-row
            align="center"
            justify="center"
          >
            <v-col cols="8">
              <v-tabs
                background-color="tabColor"
                color="white"
              >
                <v-tab>Cheque</v-tab>
                <v-tab>Bank Transfer</v-tab>
                <v-tab>Cash</v-tab>

                <v-tab-item>
                  <v-row>
                    <v-col cols="4">
                      <v-text-field
                        label="Currency"
                        value="KES"
                        readonly
                        filled
                      ></v-text-field>
                    </v-col>
                    <v-col colls="8">
                      <!-- cheque dialog -->
                      <cheque-editor @chequeData="addCheque"></cheque-editor>
                      <!-- end -->
                    </v-col>
                    <v-col cols="12">
                      <!-- cheques table -->
                      <v-data-table
                        :headers="chequeHeaders"
                        :items="cheques"
                      ></v-data-table>
                      <!-- end of cheques table -->
                      <label>Cheques Total:</label>
                      {{CheckSum.toLocaleString()}}
                    </v-col>
                  </v-row>
                </v-tab-item>

                <!-- start of bank transfer -->
                <v-tab-item>
                  <v-row>
                    <v-col cols="4">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field label="G/L Account"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <DatePicker
                            @date="setTransferDate"
                            :myDate="record.TrsfrDate"
                            :title="`Posting Date`"
                          ></DatePicker>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="4">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            label="Reference"
                            v-model="record.Ref2"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            label="Amount"
                            v-model="TrsfrSum"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <!-- end of bank transfer -->

                <!-- start of cash -->
                <v-tab-item>
                  <v-col cols="4">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field label="G/L Account"></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="Amount"
                          v-model="CashSum"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-tab-item>
                <!-- end of cash -->
              </v-tabs>
            </v-col>

            <!-- start of footer -->
            <v-col cols="8">
              <v-divider></v-divider>
            </v-col>

            <v-col cols="8">
              <v-row>
                <!--  -->
                <v-col cols="8">
                  <v-row>
                    <v-col cols="6">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            label="Overall Amount"
                            v-model="TotalAmountDue"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            label="Balance Due"
                            v-model="TotalBalance"
                            readonly
                            filled
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field label="Bank Charge"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <!--  -->
                <v-col cols="4">
                  <v-text-field
                    label="Paid"
                    v-model="TotalPaid"
                  ></v-text-field>
                </v-col>
                <!--  -->
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-btn color="primary">
                    <v-icon left>mdi-plus</v-icon>Add
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- end of payment means mpdal -->

    <!-- start of bp fullscreen modal -->
    <v-dialog
      v-model="bpDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="accent"
        >
          <v-toolbar-title>
            <v-btn
              icon
              dark
              @click="bpDialog = false"
            >
              <v-icon dark>mdi-keyboard-backspace</v-icon>
            </v-btn>Select Tenant
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="bpDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-card-title>
                  <v-text-field
                    hide-details
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  show-select
                  @item-selected="clicked"
                  item-key="id"
                  :items-per-page="itemsPerPage"
                  v-model="selected"
                  :headers="bpHeaders"
                  :items="bpMasterData"
                  :single-select="singleSelect"
                  class="elevation-1"
                  :search="search"
                ></v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <!-- snackbar -->
      <snackbar ref="snackbar"></snackbar>
      <!-- end of snack-->
    </v-dialog>
    <!-- end of bp modal-->
  </v-form>
</template>


<script>
import ChequeEditor from "./cheque-dialog.vue";
export default {
  components: {
    ChequeEditor,
  },
  props: {
    tenantData: {
      type: Object,
    },
    initial: {
      type: Object,
      required: true,
    },
    editMode: {
      type: Boolean,
    },
    currencies: {
      type: Array,
    },
  },
  computed: {
    saleItemsData() {
      return this.record.inv1;
    },
    TotalPaid() {
      const total =
        this.CheckSum + parseFloat(this.TrsfrSum) + parseFloat(this.CashSum);
      return total;
    },
    TotalBalance() {
      const balance = this.TotalAmountDue - this.TotalPaid;
      return balance;
    },
    TotalAmountDue() {
      let total = 0;
      if (
        typeof this.selectedDocs != "undefined" &&
        this.selectedDocs.length > 0
      ) {
        return this.selectedDocs
          .map((item) => {
            const amount = item.SumApplied;
            total = +amount;
            return total;
          })
          .reduce((val, a) => {
            return val + a;
          });
      } else {
        return total;
      }
    },
    CheckSum() {
      let total = 0;
      if (typeof this.cheques != "undefined" && this.cheques.length > 0) {
        return this.cheques
          .map((item) => {
            const amount = item.CheckSum;
            total = +amount;
            return total;
          })
          .reduce((val, a) => {
            return val + a;
          });
      } else {
        return total;
      }
    },
  },
  data: () => ({
    search: null,
    paymentDialog: false,
    singleSelect: true,
    paymentAccount: false,
    paymentAccountAmount: 0,
    CashSum: 0,
    TrsfrSum: 0,
    AmountDue: null,
    OpenBalance: null,
    Status: "open",
    CardType: "C",
    selected: [],
    selectedItems: [],
    selectedDocs: [],
    selectedTenant: {},
    record: { parent: false, inv1: [] },
    itemsPerPage: 20,
    cheques: [],
    SlpCode: null,
    loading: false,
    draftDialog: false,
    bpDialog: false,
    itemDialog: false,
    trueValue: true,
    tab: null,
    date: null,
    DocDate: null,
    TaxDate: null,
    DocDueDate: null,
    priceList: [],
    salesEmployees: [],
    employees: [],
    bpMasterData: [],
    itemGroups: [],
    saleDocs: [],
    series: [
      { name: "Manual", value: "M" },
      { name: "HQ", value: "H" },
    ],
    bpHeaders: [
      { text: "Code", value: "CardCode" },
      { text: "Name", value: "CardName" },
      { text: "Foreign Name", value: "CardFName" },
      { text: "Group", value: "GroupCode" },
    ],
    itemHeaders: [
      { text: "Document No.", value: "invoice.DocNum" },
      { text: "Date", value: "invoice.DocDate" },
      { text: "Total", value: "invoice.DocTotal" },
      { text: "Total Payment", value: "SumApplied" },
      { text: "SAP #", value: "invoice.ExtRefDocNum" },
    ],
    chequeHeaders: [
      { text: "Due Date", value: "DueDate" },
      { text: "Amount", value: "CheckSum" },
      { text: "Country", value: "CountryCod" },
      { text: "Bank Name", value: "BankCode" },
      { text: "Branch", value: "Branch" },
      { text: "Account", value: "AcctNum" },
      { text: "Cheque No.", value: "CheckNum" },
      { text: "Endorse", value: "Endorse" },
    ],
    groups: [],
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
    tenantData: {
      handler: "setTenant",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.saleDocs = val.rct2;
      this.record = { ...val };
    },
    setTenant(val) {
      this.selectedTenant = { ...val };
    },
    openDraftDialog() {
      this.draftDialog = true;
    },
    addItemData(data) {
      this.record.inv1.push(data);
    },
    clickedDocs($event) {
      console.log($event, "event");
    },
    addCheque(data) {
      this.cheques.push(data);
    },
    clicked($event) {
      this.selectedTenant = $event.item;
      const tenant = $event.item;
      this.record.CardCode = tenant.id;
      this.record.NumAtCard = tenant.NumAtCard;
      this.record.CardName = tenant.CardName;
      // get documents
      const CustomerId = tenant.id;
      const ObjType = 13;
      const self = this;
      self.loading = true;
      this.$store
        .dispatch("get", `/customer/${CustomerId}/${ObjType}`)
        .then((res) => {
          const docs = res;
          const docsData = [];
          docs.forEach((doc) => {
            const balance = doc.DocTotal - doc.PaidToDate;
            console.log(balance, "paid");
            doc.balance = balance;
            doc.SumApplied = balance;
            docsData.push(doc);
          });
          self.saleDocs = docsData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
      // end
      this.bpDialog = false;
    },
    setTransferDate(date) {
      this.record.TrsfrDate = date;
    },
    setPostingDate(date) {
      this.record.DocDate = date;
    },
    setDeliveryDate(date) {
      this.record.DocDueDate = date;
    },
    setDocumentDate(date) {
      this.record.TaxDate = date;
    },
    sendData() {
      this.record.DocTotal = this.TotalAmountDue;
      this.record.CheckSum = this.CheckSum;
      this.record.CashSum = this.CashSum;
      this.record.TrsfrSum = this.TrsfrSum;
      this.record.rct2 = this.selectedDocs;
      this.record.rct1 = this.cheques;
      this.$emit("data", this.record);
    },
    openBp() {
      this.selected = [];
      this.bpDialog = true;
    },
    getBpMaster() {
      const self = this;
      self.loading = true;
      this.$store
        .dispatch("get", `/bp_masterdata`)
        .then((res) => {
          self.bpMasterData = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    setDefaults() {
      // date defaults
      const date = new Date().toISOString().substr(0, 10);
      this.record.DocDate = date;
      this.record.TaxDate = date;
      this.record.DocDueDate = date;
      // Currency source
      this.record.CurSource = "L";
      this.record.Series = "M";
    },
    save() {
      this.$refs.snackbar.show("Data saved", "green");
    },
    cancel() {
      this.$refs.snackbar.show("Cancelled", "green");
    },
    open() {},
    close() {
      console.log("Dialog closed");
    },
  },
  created() {
    this.setDefaults();
    this.getBpMaster();
  },
};
</script>